'use client'

import React from 'react'
import { useSearchParams } from 'next/navigation'
import { usePathname, useRouter } from '@/lib/navigation'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
} from '@/ui/components/ui/select'
import { AnalyticsContext } from '@/ui/context/AnalyticsContext'

import type { LOCALE } from '@ralph/db/utils/config'
import { LOCALES } from '@ralph/db/utils/config'

export const LanguageSwitch = (props: { locale: LOCALE }) => {
  const router = useRouter()
  const [_, startTransition] = React.useTransition()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const { changeLocale } = React.useContext(AnalyticsContext)

  return (
    <Select
      onValueChange={(newLocale) => {
        startTransition(() => {
          const query = Object.fromEntries(searchParams.entries())

          changeLocale(newLocale as LOCALE)
          router.replace({
            pathname: `/${newLocale}/${pathname}` as any,
            query: {
              ...query, // Preserve existing query parameters
              locale: newLocale,
            },
          })
        })
      }}
    >
      <SelectTrigger className="w-[60px]">
        {props.locale.toUpperCase()}
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {LOCALES.map((l) => (
            <SelectItem key={l} value={l}>
              {l.toUpperCase()}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}
